import React, { useState } from 'react';
import '../styles.scss';
import CustomTableBody from '../customTableBody/CustomTableBody';
import LikeButton from '../../button/likeButton/LikeButton';

const OrderTable = ({ item, names }) => {
  const [active, setActive] = useState(false);
  const { title, price } = item;

  // comment functionality favorites
  // const handleFavorites = () => {
  //   setActive(!active);
  // };

  return (
    <div className="custom-table-container order">
      <div className="custom-table-title">
        <div className="collection-summary">
          <div className="title head1 bold">{title}</div>
          <div className="title head3 bold">${price}</div>
        </div>
        <div className="add-to-favorites-container">
          {/* 
          comment functionality favorites
          <LikeButton onClick={handleFavorites} active={active} /> */}
        </div>
      </div>
      <CustomTableBody item={item} names={names} hideEmpty />
    </div>
  );
};

export default OrderTable;
