import React, { useState, useEffect } from 'react';

import PrimaryButton from '../../components/shared/button/PrimaryButton';
import OrderTable from '../../components/shared/table/orderTable/OrderTable';
import CollectionSlider from '../../components/collections/collectionSlider/CollectionSlider';
import Layout from '../../components/layout/Layout';
import './product.scss';

const Product = ({ location, pageContext }) => {
  const [collectionItem, setCollectionItem] = useState({});
  const [description, setDescription] = useState({});
  const collectionName = location?.href?.split('/')?.[6];
  const order = JSON.parse(localStorage.getItem('order'));
  const isEditing = order?.isEditing;

  const names = {
    lensType: 'Lens Type',
    packageDetails: 'Package Details',
    materialOfContent: 'Material and % of content',
    water: 'Water % of content',
    vendor: 'Manufacturer',
    availability: 'Availability',
  };

  const parseSpecs = (html) => {
    const doc = document.createElement('html');
    doc.innerHTML = html;
    const specs = [...doc.getElementsByClassName('spec-text')].map((item) => item.innerText);

    return Object.entries(names).reduce(
      (result, [key, item], index) => ({
        ...result,
        [key]: specs[index],
      }),
      {},
    );
  };

  useEffect(() => {
    const tempProduct = { ...pageContext?.product, availability: 'In Stock' };
    const parsedDescription = parseSpecs(tempProduct.description);
    setCollectionItem(tempProduct);
    setDescription(tempProduct);
  }, [pageContext]);

  const handleNewPatient = () => {
    localStorage.setItem('order', JSON.stringify({}));
  };

  const handleSelectedPatient = () => {
    localStorage.setItem(
      'order',
      JSON.stringify({
        ...order,
        item: {},
        patient: { ...order.patient, shipmentFrequency: '' },
        isEditing: false,
      }),
    );
  };

  return (
    <Layout location={location}>
      <div className="container">
        <div className="products-navigation">
          <span className="title">{`Products > ${collectionItem?.title}`}</span>
        </div>
        <div className="card-container">
          <div className="collection-image-container">
            <CollectionSlider collectionItem={collectionItem} />
          </div>
          <div className="collection-card-container">
            <OrderTable item={description} names={names} />

            <div className="orders-btn-container">
              <div className="order-for">Order for: </div>
              {!isEditing ? (
                <div className="orders-btn">
                  <PrimaryButton
                    onClick={handleNewPatient}
                    redirectTo={`/doctor/account/collections/${collectionName}/order`}
                    label="New Patient"
                    purple
                  />
                  <PrimaryButton
                    redirectTo={`/doctor/account/collections/${collectionName}/existing-patient`}
                    label="Existing Patient"
                    white
                  />
                </div>
              ) : (
                <PrimaryButton
                  label="Selected Patient"
                  onClick={handleSelectedPatient}
                  redirectTo={`/doctor/account/collections/${collectionName}/order`}
                  purple
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Product;
