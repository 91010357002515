import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import './CollectionSlider.scss';

const CollectionSlider = ({ collectionItem }) => {
  const { imageUrl } = collectionItem;

  const settings = {
    dots: true,
    dotsClass: 'slick-dots slick-thumb',
    infinite: false,
    // customPaging: function (i) {
    //   return (
    //     <a>
    //       <img src={images[i].default} />
    //     </a>
    //   );
    // },
  };

  return (
    <>
      <Slider {...settings}>
        <div>
          <img src={imageUrl} />
        </div>
      </Slider>
    </>
  );
};

export default CollectionSlider;
