import React from 'react';
import classNames from 'classnames';
import './CustomTableBody.scss';

const CustomTableBody = ({ item, names, hideEmpty, ...props }) => {
  const itemsToRender = item
    ? Object.entries(names)
        .filter(([key, value]) => {
          if (hideEmpty) return key in item && item[key] !== null;
          return true;
        })
        .reduce((acc, el) => ({ ...acc, [el[0]]: item[el[0]] }), {})
    : null;

  return itemsToRender
    ? Object.entries(itemsToRender).map(([key, value], index) => (
        <CollectionItemRow
          key={index}
          name={names[key]}
          value={value}
          even={props?.odd ? index % 2 === 0 : index % 2 !== 0}
          half={props?.half}
          extraClick={props?.extraClick && key in props?.extraClick && props?.extraClick[key]}
        />
      ))
    : null;
};

const CollectionItemRow = ({ name, value, ...props }) => {
  const infoRowClass = classNames({
    'info-row': true,
    even: props?.even,
    half: props?.half,
  });

  return (
    <div className={infoRowClass}>
      <div className="name title head7 extrabold">{name}</div>
      {props?.extraClick ? (
        <a className="value title head7 extrabold extra" onClick={props?.extraClick}>
          {value}
        </a>
      ) : (
        <div className="value title head7">{value || '—'}</div>
      )}
    </div>
  );
};

export default CustomTableBody;
